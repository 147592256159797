/*
 * This file contains CRUD functionality on the React Native (client) end.
 * All `get` requests should have idToken as a header.
*/

import http from "../http-common";

class UserDataService {
  getAll(idToken) {
    console.log("token in profle.service: " + idToken);
    return http.get(`/users/?idToken=${idToken}`);
  }

  get(idToken) {
    return http.get(`/users/${idToken}`);
  }

  create(data) {
    return http.post(`/users`, data);
  }

  update(idToken, data) {
    console.log("Update User | Client: " + idToken);
    return http.put(`/users/?idToken=${idToken}`, data);
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }

  deleteAll() {
    return http.delete(`/users`);
  }

  findByTitle(title) {
    return http.get(`/users?title=${title}`);
  }

  webGet(username) {
    console.log("User Service: ", username); 
    return http.get(`/users/${username}/get-user`);
  }
  webGetSocial(username) {
    // console.log("Social Handle Service: ", username);
    return http.get(`/social_handles/${username}/get-handles`);
  }

  webValidateUsername(username) {
    return http.get(`/users/${username}/validate-username`);
  }

  webLinks(username) {
    console.log("Website Service: ", username);
    return http.get(`/websites/${username}/get-websites`);
  }

  webCountFollowers(username) {
    console.log("Follow Service: ", username);
    return http.get(`/follows/${username}/web-count-followers`);
  }

  webCountFollowing(username) {
    console.log("Follow Service: ", username);
    return http.get(`/follows/${username}/web-count-following`);
  }
}

export default new UserDataService();

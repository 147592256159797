import React, {useEffect, useState} from 'react';
import Key from './Key';
import Error from './Error'
import { Redirect, Route, useParams } from 'react-router-dom';
import UserDataService from '../services/user.service'

// https://stackoverflow.com/questions/45266423/dynamic-paths-in-react-router

function UsernameValidation() {
    function properComponent(props) {
        if (props === 1) return true;
        else return false;
    };

    // Fetch the param
    let handle = useParams().handle;
    let [validUsername, setValidUsername] = useState(1);
    UserDataService.webValidateUsername(handle)
            .then(Response => {
                // console.log(Response.data);
                setValidUsername(Response.data.result);
            })
            .catch(e => {
                console.log(e);
              })

    // Check the param
    // if (handle === "ekkin2") {validHandle = true;}
    let intermediate = properComponent(validUsername);


    // Return the correct route
    return intermediate ? <Key /> : <Error />
}

export default UsernameValidation;

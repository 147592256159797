/*
 * This file contains CRUD functionality on the React Native (client) end.
*/

import http from "../http-common";

class WebsiteDataService {
  getAll(idToken) { // gets all of the websites of a specific user
    return http.get(`/websites/?idToken=${idToken}`);
  }

  getAllUser(idToken, userId) {
    return http.get(`/websites/user/?idToken=${idToken}&userId=${userId}`);
  }

  get(idToken) {
    return http.get(`/websites/${idToken}`);
  }

  create(data) {
    return http.post("/websites", data);
  }

  update(idToken, data) {
    console.log("Update Website | Client: " + idToken);
    return http.put(`/websites/?idToken=${idToken}`, data);
  }

  delete(idToken) {
    return http.delete(`/websites/${idToken}`);
  }

  deleteAll() {
    return http.delete(`/websites`);
  }

  findByTitle(title) {
    return http.get(`/websites?title=${title}`);
  }
}

export default new WebsiteDataService();

import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
// import profilePic from '../images/EkinTiu.jpg';

import phone from '../images/phone.jpg';
import facebook from '../images/facebook.jpg';
import discord from '../images/discord.jpg';
import instagram from '../images/instagram.jpg';
import snapchat from '../images/snapchat.jpg';
import spotify from '../images/spotify.jpg';
import youtube from '../images/youtube.png';
import linkedin from '../images/linkedin.png';
import twitter from '../images/twitter.png';
import tiktok from '../images/tiktok.png';
import twitch from '../images/twitch.png';
import playStore from '../images/google-play-badge.png';
import AppStore from '../images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg'
import './Key.css';
// import Error from './Error';

import UserDataService from '../services/user.service';

import ProfileDataService from '../services/profile.service';
import SocialHandleDataService from '../services/social_handle.service';
import PlatformDataService from '../services/platform.service';
import WebsiteDataService from '../services/website.service';
import FollowDataService from '../services/follow.service';


function Key() {
    console.log(useParams().handle);
    const {handle} = useParams();
    let [validUsername, setValidUsername] = useState(1);
    UserDataService.webValidateUsername(handle)
        .then(Response => {

            setValidUsername(Response.data.result);
        })


    function nameFontSize(first, last) {
        let nameLen = first.length + last.length;
        let maxNameFontSize = 22; // We should make this a constant variable at some point
        let fontSize = 1 / nameLen * 264;
        if (fontSize > maxNameFontSize) {
          fontSize = maxNameFontSize;
        }
        return fontSize;
      }

    function displayEmail() {
        if (email !== null) {
            return (
                <div style={{marginBottom: 0}}>
                    <span style={{
                        fontWeight: 550
                    }}>Email: </span>{email}
                </div>
            )
        }
    }

    function displayDiscord() {
        if (discordLink !== null) {
            return (
                <div style={{marginBottom: 0}}>
                    <span style={{
                        fontWeight: 550
                    }}>Discord: </span>{discordLink}
                </div>
            )
        }
    }

    function countFormatting(count) {
        if (count < 1000) {
            return(count)
        } else if (count >= 1000 && count < 1000000) {
            let countThousand = count / 1000;
            let countRoundedThousand = countThousand.toFixed(1)

            return(
                <span>{countRoundedThousand}K</span>
            )
        } else if (count >= 1000000) {
            let countMillion = count / 1000000
            let countRoundedMillion = countMillion.toFixed(0)

            return(
                <span>{countMillion}M</span>
            )
        }
    }

    // User data state vars
    const[firstName, setFirstName] = useState('');
    const[lastName, setLastName] = useState('');
    const[nameSize, setNameSize] = useState(20);
    const[username, setUsername] = useState('');
    const[numFollowers, setNumFollowers] = useState(null);
    const[numFollowing, setNumFollowing] = useState(null);
    const[imageUrl, setImageUrl] = useState('');
    const[bio, setBio] = useState('');
    const[handles, setHandles] = useState([]);
    const[phoneNumber, setPhoneNumber] = useState('');
    const[discordLink, setDiscordLink] = useState(null);
    const[instagramLink, setInstagramLink] = useState('');
    const[facebookLink, setFacebookLink] = useState('');
    const[spotifyLink, setSpotifyLink] = useState('');
    const[snapchatLink, setSnapchatLink] = useState('');
    const[youtubeLink, setYoutubeLink] = useState('');
    const[linkedinLink, setLinkedinLink] = useState('');
    const[twitterLink, setTwitterLink] = useState('');
    const[tiktokLink, setTiktokLink] = useState('');
    const[twitchLink, setTwitchLink] = useState('');

    const[phoneOpacity, setPhoneOpacity] = useState(0.4);
    const[instagramOpacity, setInstagramOpacity] = useState(0.4);
    const[facebookOpacity, setFacebookOpacity] = useState(0.4);
    const[spotifyOpacity, setSpotifyOpacity] = useState(0.4);
    const[snapchatOpacity, setSnapchatOpacity] = useState(0.4);
    const[youtubeOpacity, setYoutubeOpacity] = useState(0.4);
    const[linkedinOpacity, setLinkedinOpacity] = useState(0.4);
    const[twitterOpacity, setTwitterOpacity] = useState(0.4);
    const[tiktokOpacity, setTiktokOpacity] = useState(0.4);
    const[twitchOpacity, setTwitchOpacity] = useState(0.4);
    const[email, setEmail] = useState(null);

    const[linkNames, setLinkNames] = useState(["", "", "", "", ""]);
    const[linkUrls, setLinkUrls] = useState(["", "", "", "", ""]);
    // const[websiteName1, setWebSiteName1] = useState('')
    // const[websiteLink1, setWebSiteLink1] = useState('')
    // const[websiteName2, setWebSiteName2] = useState('')
    // const[websiteLink2, setWebSiteLink2] = useState('')
    // const[websiteName3, setWebSiteName3] = useState('')
    // const[websiteLink3, setWebSiteLink3] = useState('')
    // const[websiteName4, setWebSiteName4] = useState('')
    // const[websiteLink4, setWebSiteLink4] = useState('')
    // const[websiteName5, setWebSiteName5] = useState('')
    // const[websiteLink5, setWebSiteLink5] = useState('')

    // if (validUsername === 0) {return <Error />}
    useEffect( () => {
        UserDataService.webGet(handle)
            .then(Response => {
                if (Response.data.length !== 0) {
                setFirstName(Response.data[0].firstName);
                setLastName(Response.data[0].lastName);
                setUsername(handle);
                setBio(Response.data[0].profile.bio);
                setImageUrl(Response.data[0].profile.imageUrl);
                setNameSize(nameFontSize(Response.data[0].firstName, Response.data[0].lastName))

                }
            })
            .catch(e => {
                console.log(e);
            });
        UserDataService.webCountFollowers(handle)

            .then(Response => {

                setNumFollowers(Response.data.result)

            })
            .catch(e => {
                console.log(e);
            });

        UserDataService.webCountFollowing(handle)

            .then(Response => {
                setNumFollowing(Response.data.result)
            })
            .catch(e => {
                console.log(e);
            });

        UserDataService.webGetSocial(handle)
            .then(Response => {

                if (Response.data.length !== 0) {
                    let handlesArray = [];
                    for (let i = 0; i < Response.data.length; i++) {
                        let platformName = Response.data[i].platform.name;
                        let platformValue = Response.data[i].value

                        if (platformName === "Phone") {
                            let phoneNumberLink = `tel:${platformValue}`;
                            setPhoneNumber(phoneNumberLink);
                            setPhoneOpacity(1);
                        } else if (platformName === "Instagram") {
                            let instaBuilderLink = `https://www.instagram.com/${platformValue}/`;
                            setInstagramLink(instaBuilderLink)
                            setInstagramOpacity(1)
                        } else if (platformName === "Snapchat") {
                            let snapchatBuilderLink = `https://www.snapchat.com/add/${platformValue}/`
                            setSnapchatLink(snapchatBuilderLink)
                            setSnapchatOpacity(1)
                        } else if (platformName === "Twitter"){
                            let twitterBuilderLink = `https://www.twitter.com/${platformValue}/`
                            setTwitterLink(twitterBuilderLink)
                            setTwitterOpacity(1)
                        } else if (platformName === "LinkedIn") {
                            setLinkedinLink(platformValue)
                            setLinkedinOpacity(1)
                        } else if (platformName === "Email") {
                            setEmail(platformValue)
                        } else if (platformName === "Facebook") {
                            setFacebookLink(platformValue)
                            setFacebookOpacity(1)
                        } else if (platformName === "Discord") {
                            setDiscordLink(platformValue)
                        } else if (platformName === "Spotify") {
                            setSpotifyLink(platformValue)
                            setSpotifyOpacity(1)
                        } else if (platformName === "Youtube") {
                            setYoutubeLink(platformValue)
                            setYoutubeOpacity(1)
                        } else if (platformName === "Twitch") {
                            setTwitchLink(platformValue)
                            setTwitchOpacity(1)
                        } else if (platformName === "TikTok") {
                            setTiktokLink(platformValue)
                            setTiktokOpacity(1)
                        }

                    }
                }
            })
            .catch(e => {
                console.log(e);
            });

            UserDataService.webLinks(handle)

                .then(Response => {
                    var pulledWebNames = ["", "", "", "", ""];
                    var pulledWebLinks = ["", "", "", "", ""];
                    if (Response.data.length !== 0){


                        console.log(Response.data);


                        for (var i = 0; i < Response.data.length; i++) {
                            pulledWebNames[i] = Response.data[i].label
                            pulledWebLinks[i] = Response.data[i].link
                        }
                        console.log(pulledWebLinks)
                        console.log(pulledWebNames)
                        setLinkNames(pulledWebNames)
                        setLinkUrls(pulledWebLinks)

                    }


                })
                .catch(e => {
                    console.log(e);
                });



    }, []);
    return (
        <div style={{
            background: 'linear-gradient( 180deg, rgba(121,194,243,1) 22.6%, turquoise 67.7% )',
            height: '100vh', display: 'flex', justifyContent: 'center',
            flexDirection: 'column', alignItems: 'center',
        }}>
            <div style={{
                backgroundColor: 'white', height: 520, width: 320,
                 borderRadius: 10, border: '8px solid white'
            }}>

                <div style={{
                    backgroundColor: 'turquoise', height: 80, width: 320, margin: 'auto',
                    borderTopLeftRadius: 9, borderTopRightRadius: 9,

                    display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
                }}>

                    <div style={{
                        color: 'white', fontFamily: 'Poppins', fontSize: nameSize, fontWeight: 600,
                        display: 'grid', marginLeft: 129, marginTop: 10,
                    }}>
                    {firstName} {lastName}
                    <div style={{
                        fontSize: 18, marginTop: -17, fontWeight: 400,
                    }}>
                    {handle}
                    </div>
                    </div>


                </div>
                <div style={{
                    paddingTop: 30,
                    display: 'flex',
                }}>
                    <div style={{marginTop: -9}}>
                        <img src={imageUrl} style={{
                            marginTop: -90, marginLeft: 10,
                            width: 95, height: 95, borderRadius: 100,
                            objectFit: 'cover',
                            backgroundAttachment: 'fixed',
                            border: '4px solid white',
                            }}alt=""/>
                    </div>
                    <div style={{
                            display: 'flex', height: 30, // backgroundColor: 'firebrick',
                            marginLeft: 0, marginTop: -20, textAlign: 'end'
                        }}>
                            <span style={{
                                color: '#3C3C3C', fontFamily: 'Poppins', fontSize: 11, fontWeight: 650,
                                width: 35, marginRight: 6, marginTop: 1,
                                }}>
                                {countFormatting(numFollowers)}
                            </span>
                            <span style={{
                                color: '#4e4e4e', fontFamily: 'Poppins', fontSize: 11, fontWeight: 600,
                                marginTop: 1,
                                }}>
                                Followers
                            </span>

                    </div>
                    <div style={{
                        display: 'flex', height: 30,
                        marginLeft: 3, marginRight: 5, marginTop: -20, textAlign: 'end'
                    }}>
                        <span style={{
                            color: '#3C3C3C', fontFamily: 'Poppins', fontSize: 11, fontWeight: 650,
                            width: 35, marginRight: 6, marginTop: 1.5,
                            }}>
                            {countFormatting(numFollowing)}
                        </span>
                        <span style={{
                            color: '#4e4e4e', fontFamily: 'Poppins', fontSize: 11, fontWeight: 600,
                            marginTop: 1.5,
                            }}>
                            Following
                        </span>

                    </div>

                </div>
                <div style={{marginTop: 10, marginLeft: 5,}}>
                    <span style={{
                        marginLeft: 15, marginRight: 19,
                        color: '#3C3C3C', fontFamily: 'Poppins', fontSize: 13, fontWeight: 700,
                    }}>
                        About Me
                        <div style={{
                            color: '#3C3C3C', fontFamily: 'Poppins', fontSize: 11, fontWeight: 450,
                            marginTop: 5, marginLeft: 15,
                        }}>
                            <div style={{marginBottom: 3}}>{bio}</div>
                            {displayEmail()}
                            {displayDiscord()}

                        </div>
                    </span>
                </div>
                <div style={{marginTop: -5, marginLeft: 5,}}>
                    <span style={{
                        marginLeft: 15, marginRight: 30,
                        color: '#3C3C3C', fontFamily: 'Poppins', fontSize: 13, fontWeight: 700,
                    }}>
                        Social Handles
                    </span>
                </div>
                <div style={{marginTop: 10, marginLeft: 19}}>
                    <a href={phoneNumber}>
                    <img src={phone} style={{width: 45, height: 45, opacity: phoneOpacity}} alt="facebook"/>
                    </a>
                    <a href={facebookLink}>
                    <img src={facebook} style={{width: 45, height: 45, marginLeft: 14, opacity: facebookOpacity}} alt="facebook"/>
                    </a>
                    <a href={instagramLink}>
                    <img src={instagram} style={{width: 45, height: 45, marginLeft: 14, opacity: instagramOpacity}}alt="instagram"/>
                    </a>
                    <a href={snapchatLink}>
                    <img src={snapchat} style={{width: 45, height: 45,marginLeft: 14, opacity: snapchatOpacity}}alt="snapchat"/>
                    </a>
                    <a href={linkedinLink}>
                    <img src={linkedin} style={{width: 45, height: 45,marginLeft: 14, opacity: linkedinOpacity}}alt="linkedIn"/>
                    </a>

                </div>
                <div style={{marginTop: 20, marginLeft: 19, }}>
                    <a href={tiktokLink}>
                    <img src={tiktok} style={{width: 45, height: 45,marginLeft: 0, opacity: tiktokOpacity}}alt="tiktok"/>
                    </a>
                    <a href={spotifyLink}>
                    <img src={spotify} style={{width: 45, height: 45,marginLeft: 14, opacity: spotifyOpacity}}alt="spotify"/>
                    </a>
                    <a href={youtubeLink}>
                    <img src={youtube} style={{width: 45, height: 45,marginLeft: 14, borderRadius: 10, backgroundColor: 'whitesmoke', opacity: youtubeOpacity}}alt="youtube"/>
                    </a>
                    <a href={twitterLink}>
                    <img src={twitter} style={{width: 45, height: 45,marginLeft: 14, borderRadius: 10, opacity: twitterOpacity}}alt="twitter"/>
                    </a>
                    <a href={twitchLink}>
                    <img src={twitch} style={{width: 45, height: 45,marginLeft: 14, borderRadius: 10, opacity: twitchOpacity}}alt="twitch"/>
                    </a>
                </div>
                <div style={{marginTop: 10, marginLeft: 5, color: '#3C3C3C', fontFamily: 'Poppins',}}>
                    <span style={{
                        marginLeft: 15, marginRight: 30,
                        color: '#3C3C3C', fontFamily: 'Poppins', fontSize: 13, fontWeight: 700,
                    }}>
                        Links
                        <div style={{marginLeft: 15, fontSize: 12, fontWeight: 600, marginTop: 5, color:'darkgoldenrod' }}>
                            <a href={linkUrls[0]} style={{textDecorationLine: 'none', color: '#0e8ee2'}}> {linkNames[0]} </a>
                        </div>
                        <div style={{marginLeft: 16, fontSize: 12, fontWeight: 600, marginTop: 2, textDecorationColor: 'hotpink'}}>
                            <a href={linkUrls[1]} style={{textDecorationLine: 'none', color: '#0e8ee2'}}> {linkNames[1]} </a>
                        </div>
                        <div style={{marginLeft: 16, fontSize: 12, fontWeight: 600, marginTop: 2}}>
                            <a href={linkUrls[2]} style={{textDecorationLine: 'none', color: '#0e8ee2'}}> {linkNames[2]} </a>
                        </div>
                        <div style={{marginLeft: 16, fontSize: 12, fontWeight: 600, marginTop: 2}}>
                            <a href={linkUrls[3]} style={{textDecorationLine: 'none', color: '#0e8ee2'}}> {linkNames[3]} </a>
                        </div>
                        <div style={{marginLeft: 16, fontSize: 12, fontWeight: 600, marginTop: 2}}>
                            <a href={linkUrls[4]} style={{textDecorationLine: 'none', color: '#0e8ee2'}}> {linkNames[4]} </a>
                        </div>
                    </span>


                </div>
            </div>
            <div style={{marginLeft: 15, marginRight: 0, marginTop: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <a href={'https://apps.apple.com/us/app/mykeychain-your-social-cloud/id1582327971'}>
                    <img src={AppStore} style={{height: 35}} alt="AppStore" />
                </a>
                <a href={'https://play.google.com/store/apps/details?id=com.keychainapp.keychain'}>
                    <img src={playStore} style={{aspectRatio: 2.584, height: 50}} alt="playstore" />
                </a>

            </div>
        </div>
    )
}

export default Key;

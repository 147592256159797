import React from 'react';
import { Link } from 'react-router-dom';

import playStore from '../../../images/google-play-badge.png';
import AppStore from '../../../images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg'

function SignUp() {
    return (
        <div style= {{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            height: '100vh',

        }}>
        <a href={'https://apps.apple.com/us/app/met-your-social-keychain/id1563602978'}>
            <img src={AppStore} style={{height: 35}} alt="AppStore" />
        </a>
        <a href={'https://play.google.com/store/apps/details?id=com.met.met'}>
            <img src={playStore} style={{aspectRatio: 2.584, height: 50}} alt="playstore" />
        </a>
        </div>
    )
}

export default SignUp; 
// Home.js file
import React from 'react'
import HeroSection from '../../HeroSection.js';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data.js';
import Navbar from '../../Navbar.js';
import Footer from '../Footer/Footer.js';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import logo from '../../../logo192.png';
import playStore from '../../../images/google-play-badge.png'
import AppStore from '../../../images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg'

import profile from '../../../images/WebsiteImages/profile_website.png'
import newqrpropersize from '../../../images/WebsiteImages/qr_website.png'
import social from '../../../images/WebsiteImages/social_website.png'
import webview from '../../../images/WebsiteImages/webview_website.png'

function Home() {
    console.log(isBrowser); 
    console.log(isMobile);
    const data = [1, 2]; 
    let timeElapsed = 0;

    if (isMobile) {
        return (
            <>
                {/* logo and myKeychain combo*/}
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 25}}>
                    <img src={logo} style={{width: 45, height: 45, }}alt="logo"/>
                    <span style={{
                        fontFamily: 'Poppins', display: 'flex', justifyContent: 'center', 
                        alignItems: 'center', fontSize: 25, color: '#3c3c3c'
                    }}> myKeychain
                    </span>
                </div>

                {/* badges */}
                <div style={{marginLeft: 20, marginRight: 0, marginTop: 5, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <a href={'https://apps.apple.com/us/app/mykeychain-your-social-cloud/id1582327971'}>
                        <img src={AppStore} style={{height: 32}} alt="AppStore" />
                    </a>
                    <a href={'https://play.google.com/store/apps/details?id=com.keychainapp.keychain'}>
                        <img src={playStore} style={{aspectRatio: 2.584, height: 45, marginTop: 0}} alt="playstore" />
                    </a>

                </div>
                {/* each section */}
                <>
                    <div style={{ marginLeft: 15, marginRight: 15, marginTop: 40}}>
                        <div style={{ fontFamily: 'Poppins', fontSize: 15, color: '#ff5349', fontWeight: 'bold',

                        }}>WHAT IS MYKEYCHAIN?</div>
                        <div style={{fontFamily: 'Poppins', fontSize: 25,
                        width: 300,
                        }}>A global contacts directory on the cloud</div>
                        <div style={{fontFamily: 'Poppins', fontSize: 15, color: '#3c3c3c'

                        }}>One app to store yours, and millions of other people's contact information, social media, and professional handles!</div>

                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img src={profile} style={{height: 600}} alt="playstore" />
                    </div>
                </>

                <>
                    <div style={{ marginLeft: 15, marginRight: 15, marginTop: 20}}>
                        <div style={{ fontFamily: 'Poppins', fontSize: 15, color: '#ff5349', fontWeight: 'bold',

                        }}>WHY MYKEYCHAIN</div>
                        <div style={{fontFamily: 'Poppins', fontSize: 25,
                        width: 300,
                        }}>Maintain connections for a lifetime</div>
                        <div style={{fontFamily: 'Poppins', fontSize: 15, color: '#3c3c3c'

                        }}>A seamless, integrated bundling of contacts, enabling you to connect with others on the go, and look back at your former connections in an instant!
                        </div>

                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img src={webview} style={{height: 600}} alt="playstore" />
                    </div>
                </>

                <>
                    <div style={{ marginLeft: 15, marginRight: 15, marginTop: 20}}>
                        <div style={{ fontFamily: 'Poppins', fontSize: 15, color: '#ff5349', fontWeight: 'bold',

                        }}>EASY SETUP</div>
                        <div style={{fontFamily: 'Poppins', fontSize: 25,
                        width: 300,
                        }}>Quick and simple onboarding process</div>
                        <div style={{fontFamily: 'Poppins', fontSize: 15, color: '#3c3c3c'

                        }}>Start connecting in just a few minutes. Create an account, enter your handles, and you're all set!</div>

                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img src={social} style={{height: 600}} alt="playstore" />
                    </div>
                </>

                <>
                    <div style={{ marginLeft: 15, marginRight: 15, marginTop: 20}}>
                        <div style={{ fontFamily: 'Poppins', fontSize: 15, color: '#ff5349', fontWeight: 'bold',

                        }}>APPLICABLE EVERYWHERE</div>
                        <div style={{fontFamily: 'Poppins', fontSize: 25,
                        width: 300,
                        }}>Scan it and see it</div>
                        <div style={{fontFamily: 'Poppins', fontSize: 15, color: '#3c3c3c'

                        }}>First year in college? First day on the job? Need to get in touch with an old friend? No problem! Share your customized URL or view other people's information with the click of a button!</div>

                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img src={newqrpropersize} style={{height: 600}} alt="playstore" />
                    </div>
                </>

                {/* footer */}
                <div style={{backgroundColor: '#1c2237', height: 100, display: 'grid', alignItems: 'center', paddingLeft: 15}}>
                    <div>
                        <a target= '_blank' href={'https://www.mykeychain.app/mykeychain.ysc'} style={{textDecorationLine: 'none', color: '#fff', fontFamily: 'Poppins', fontSize: 22}}>
                            Contact Us
                        </a>
                    </div>
                    <div style={{color: '#fff', fontFamily: 'Poppins', fontSize: 13}}>
                        Keychain - Your Social Cloud, Inc. © 2021  
                    </div>
                </div>
            </>
        )
    } 
    return (
        <>
            <Navbar />
            <HeroSection {...homeObjOne} />
            <HeroSection {...homeObjTwo} />
            <HeroSection {...homeObjThree} />
            <HeroSection {...homeObjFour} />
            <Footer />
        </>
    )
}

export default Home;

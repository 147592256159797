/*import React from 'react';
import { Route } from 'react-router-dom';
import UsernameValidation from './components/UsernameValidation'
import './App.css'

function App() {

  return (
    <div >
      <Route path="/" exact>
        <div style={{height: '100vh', display: 'grid',
            flexDirection: 'row', alignItems: 'center',
        background: 'linear-gradient( 160deg, #D81C35 12.6%, #FF8A00 87.7% )',}}>
        <div style={{

          color: 'white', fontSize: 40, textAlign: 'center',
          marginTop: -100, marginBottom: 0, fontWeight: 650,
          fontFamily: 'Poppins',
          }}>Keychain<div style={{
            marginTop: 0, fontSize: 20, fontWeight: 550,
          }}>mykeychain.app/username</div><div style={{
            marginRight: 22, fontSize: 20, fontWeight: 550, marginTop: 20
          }}>1 - Download the app</div><div style={{
            marginRight: 35, fontSize: 20, fontWeight: 550, marginTop: 10,
          }}>2 - Get a custom URL </div><div style={{
            marginTop: 10, fontSize: 20, fontWeight: 550,
          }}>3 - Share with the world!</div></div>
        </div>
      </Route>
      <Route path="/:handle" >
        <UsernameValidation />
      </Route>

    </div>
  );
}

export default App;*/

import React from 'react';
// import { Route } from 'react-router-dom';
import UsernameValidation from './components/UsernameValidation'
import './App.css'
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/HomePage/Home';
import SignUp from './components/pages/SignUp/signup';
import FAQs from './components/pages/FAQ/faq';

function App() {

  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home}/>
        {/* <Route path='/sign-up' exact component={SignUp}/>
        <Route path='/faqs' exact component={FAQs}/> */}
        <Route path='/:handle'>
          <UsernameValidation />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;




// Make privacy policy page (with text)
// Same as above for terms of service
// Finish sign up page
// Contact page
// FAQs page (mostly done, just import from Vedant's code)

// In Footer: get rid of Youtube icons, create FB, IG, and Twitter profiles and
// switch links to these accounts, replace Keychain icon with white icon in Slack

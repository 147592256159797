/*
 * This file contains CRUD functionality on the React Native (client) end.
*/

import http from "../http-common";

class FollowDataService {
  getAll(idToken) {
    return http.get(`/follows/?idToken=${idToken}`);
  }

  getAllFollowers(idToken) {
    return http.get(`/follows/all-followers/?idToken=${idToken}`)
  }

  getRecentFollowers(idToken) {
    return http.get(`/follows/recent-followers/?idToken=${idToken}`)
  }

  getRecentConnections(idToken) {
    return http.get(`/follows/recent-connections/?idToken=${idToken}`);
  }

  countFollowers(idToken, userId) {
    return http.get(`/follows/${idToken}/count-followers/?userId=${userId}`);
  }

  countFollowing(idToken, userId) {
    return http.get(`/follows/${idToken}/count-following/?userId=${userId}`);
  }

  get(idToken, userId, followedId) {
    return http.get(`/follows/${idToken}/?userId=${userId}&followedId=${followedId}`);
  }

  create(data) {
    return http.post("/follows", data);
  }

  update(id, data) {
    console.log("Update Follow | Client: " + id);
    return http.put(`/follows/?idToken=${id}`, data);
  }

  delete(idToken, userId, followedId) {
    return http.delete(`/follows/${idToken}/?userId=${userId}&followedId=${followedId}`);
  }

  deleteAll() {
    return http.delete(`/follows`);
  }

  findByTitle(title) {
    return http.get(`/follows?title=${title}`);
  }
}

export default new FollowDataService();

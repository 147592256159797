/*
 * This file contains CRUD functionality on the React Native (client) end.
*/

import http from "../http-common";

class PlatformDataService {
  getAll(idToken) {
    return http.get(`/platforms/?idToken=${idToken}`);
  }

  getAllWithHandles(idToken) {
    return http.get(`/platforms/with-handles/?idToken=${idToken}`);
  }

  get(idToken) {
    return http.get(`/platforms/${idToken}`);
  }

  create(data) {
    return http.post("/platforms", data);
  }

  update(id, data) {
    console.log("Update Platform | Client: " + id);
    return http.put(`/platforms/?idToken=${id}`, data);
  }

  delete(id) {
    return http.delete(`/platforms/${id}`);
  }

  deleteAll() {
    return http.delete(`/platforms`);
  }

  findByTitle(title) {
    return http.get(`/platforms?title=${title}`);
  }
}

export default new PlatformDataService();

/*
 * This file contains CRUD functionality on the React Native (client) end.
*/

import http from "../http-common";

class ProfileDataService {
  getAll(idToken) {
    return http.get(`/profiles/?idToken=${idToken}`);
  }

  get(idToken) {
    return http.get(`/profiles/${idToken}`);
  }

  create(data) {
    return http.post("/profiles", data);
  }

  update(idToken, data) {
    console.log("Update Profile | Client: " + idToken);
    return http.put(`/profiles/?idToken=${idToken}`, data);
  }

  delete(id) {
    return http.delete(`/profiles/${id}`);
  }

  deleteAll() {
    return http.delete(`/profiles`);
  }

  findByTitle(title) {
    return http.get(`/profiles?title=${title}`);
  }
}

export default new ProfileDataService();

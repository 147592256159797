import { React, useState } from 'react';
import { FAQs } from '../components/pages/FAQ/QandAData';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import Navbar from './Navbar';
import Footer from '../components/pages/Footer/Footer';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    marginTop: 100,
    marginBottom: 100,
    background: #fff
`;

const QandA = styled.div`
    position: absolute;
    top: 5%;
`;

const Wrap = styled.div`
    background: white;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    text-align: left;
    cursor: pointer;

    h1 {
        padding: 1rem;
        font-size: 25px;
        font-family: Poppins;
    }
`;

const Dropdown = styled.div`
    background: white;
    color: black;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border-bottom: 1px solid #009dff;
    border-top: 1px solid #009dff;

    p {
        font-size: 20px;
        font-family: Poppins;
    }
`;


function Qanda() {
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if (clicked === index) {
            return setClicked(null);
        }

        setClicked(index);
    }

    return (
        <>
            <Navbar />
            <IconContext.Provider value={{color: "black", size:"25px"}}>
                <Container>
                    <QandA>
                        {FAQs.map((item, index) => {
                            return (
                                <>
                                    <Wrap onClick={() => toggle(index)} key={index}>
                                        <h1>{item.question}</h1>
                                        <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                                    </Wrap>
                                    {clicked === index ? (
                                        <Dropdown>
                                            <p>{item.answer}</p>
                                        </Dropdown>
                                    ) : null}
                                </>
                            )
                        })}
                    </QandA>
                </Container>
            </IconContext.Provider>
            <Footer />
        </>
    )
}

export default Qanda;

/*
 * This file contains CRUD functionality on the React Native (client) end.
*/

import http from "../http-common";

class SocialHandleDataService {
  // userIdToken: id *token* of logged in user
  // idToken: id of user who we want to query from
  getAll(userIdToken, idToken, socialCircleId) {
    return http.get(`/social_handles/?userIdToken=${userIdToken}&idToken=${idToken}&socialCircleId=${socialCircleId}`);
  }

  get(idToken) {
    return http.get(`/social_handles/${idToken}`);
  }

  create(data) {
    return http.post("/social_handles", data);
  }

  update(idToken, data) {
    console.log("Update SocialHandle | Client: " + idToken);
    return http.put(`/social_handles/?idToken=${idToken}`, data);
  }

  delete(idToken, userId, platformId) {
    return http.delete(`/social_handles/${idToken}/?userId=${userId}&platformId=${platformId}`);
  }

  deleteAll() {
    return http.delete(`/social_handles`);
  }

  findByTitle(title) {
    return http.get(`/social_handles?title=${title}`);
  }
}

export default new SocialHandleDataService();

import React from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './HeroSection.css';

function HeroSection({
    lightBg, topLine, lightText, lightTextDesc, headline, description, 
    buttonLabel, img, alt, imgStart
}) {
    return (
        <>
           <div className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
               <div className="container">
                   <div className="home__hero-row" style={{display: 'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}>
                       
                       <div className="col">
                           <div className="home__hero-text-wrapper">
                               <div className="top-line">{topLine}</div>
                               <h1 className={lightText ? 'heading' : 'heading dark'}>{headline}</h1>
                               <p className={lightTextDesc ? 'home__hero-subtitle' : 'home__hero-subtitle dark'}>{description}</p>
                               <Link target='_blank' to="//apps.apple.com/us/app/mykeychain-your-social-cloud/id1582327971">
                                    <img src={require('../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg').default} />
                               </Link>
                               <Link target='_blank' to="//play.google.com/store/apps/details?id=com.keychainapp.keychain">
                                    <img src={require('../images/google-play-badge.png').default} width="155px"/>
                               </Link>
                           </div>
                       </div>
                       <div className="col">
                           <div className="home__hero-img-wrapper">
                               <img src={img} alt={alt} width="50%" className="home_hero-img"/>
                           </div>
                       </div>
                       
                   </div>
               </div>
            </div> 
        </>
    )
}

export default HeroSection;

// Footer.js
import React from 'react';
import './Footer.css';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import { MdFingerprint } from 'react-icons/md';
import logo from '../../../logo192.png'

function Footer() {
  return (
    <div className='footer-container'>
      {/* <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/'>What We Do</Link>
            <Link to='/termsofservice'>Terms of Service</Link>
            <Link to='privacypolicy'>Privay Policy</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to={'//www.mykeychain.app/mykeychain.ysc'} target='_blank'>Contact</Link>
            <Link to='/faqs'>FAQs</Link>
          </div>
        </div>
      </div> */}
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
            <img src={logo} width="40px" className='navbar-icon'/>
              myKeychain
            </Link>
          </div>
          <small className='website-rights'>Keychain - Your Social Cloud, Inc. © 2021</small>
          {/* <div className='social-icons'>
            <Link
              className='social-icon-link'
              to='//facebook.com'
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </Link>
            <Link
              className='social-icon-link'
              to='//instagram.com'
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </Link>
            <Link
              className='social-icon-link'
              to={
                '//www.youtube.com'
              }
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </Link>
            <Link
              className='social-icon-link'
              to='//twitter.com'
              target='_blank'
              aria-label='Twitter'
            >
              <FaTwitter />
            </Link>
            <Link 
                className='social-icon-link'
                to='//mykeychain.app/mykeychain.ysc'
                target='_blank'
                aria-label='Keychain' 
            >
              <img src={logo} width="30px"/>
            </Link>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Footer;

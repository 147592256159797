import React from 'react'; 


function Error() {
    return (
        
        <div style={{height: '100vh', display: 'grid', 
            flexDirection: 'row', alignItems: 'center',  
        background: 'linear-gradient( 160deg, #D81C35 12.6%, #FF8A00 87.7% )',}}>
        <div style={{
          
          color: 'white', fontSize: 35, textAlign: 'center',  
          marginTop: 0, marginBottom: 0, fontWeight: 650,
          fontFamily: 'Poppins', 
          }}>Something broke :|<div style={{
            marginTop: 10, fontSize: 30, fontWeight: 550, 
          }}>Have the right link?</div><div style={{
            marginTop: -5, fontSize: 18, fontWeight: 550, 
          }}>mykeychain.app/username</div></div>
        </div>
    )
}

export default Error; 
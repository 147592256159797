import { isDoWhileStatement } from "@babel/types";

// Data.js
export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'WHAT IS myKeychain',
    headline: 'A global contacts directory on the cloud',
    description: 'One app to store yours, and millions of other people\'s contact information, social media, and professional handles!',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: 'images/profile.png',
    alt: 'Credit Card'
  };

  export const homeObjTwo = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'WHY myKeychain',
    headline: 'Maintain connections for a lifetime',
    description:
      'A seamless, integrated bundling of contacts, enabling you to connect with others on the go, and look back at your former connections in an instant!',
    buttonLabel: 'Start Now',
    imgStart: 'start',
    img: 'images/webview.png',
    alt: 'Vault'
  };

  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'EASY SETUP',
    headline: 'Quick and simple onboarding process',
    description:
      'Start connecting in just a few minutes. Create an account, enter your handles, and you\'re all set!',
    buttonLabel: 'Start Now',
    imgStart: '',
    img: 'images/social.png',
    alt: 'Vault'
  };

  export const homeObjFour = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'APPLICABLE EVERYWHERE',
    headline: 'Scan it and see it',
    description: 'First year in college? First day on the job? Need to get in touch with an old friend? No problem! Share your customized URL or view other people\'s information with the click of a button!',
    buttonLabel: 'Learn More',
    imgStart: 'start',
    img: 'images/newqrmorearea.png',
    alt: 'Vault'
  };
